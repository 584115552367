import React, { Component } from "react";
import chart from "../../../assets/images/chart.jpg";
import axios from "axios"; 
import { ToastContainer, toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import moment from "moment";
export default class Dashboard extends Component {
  state = {
    name: "",
    role: "",
       
        
        Details: '', 
        filterDetails: [], 
        transactions: [], 
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        token: '',

  };
  async componentDidMount() {
    let checkTokenData = localStorage.getItem("userToken");
    let checkToken = JSON.parse(checkTokenData);
    //
    this.setState({
      name: checkToken.name,
      role: checkToken.role,
    });
    this.OnSearch();
    this.OnTransicationSearch();
  }
  handlePageChange(pageNumber) {
      const { Details, pageLimit } = this.state;
      const offset = (pageNumber - 1) * pageLimit;
      const filterDetails = Details.slice(offset, offset + pageLimit);
      const showingFrom = offset + 1;
      const showingTo = offset + pageLimit;
      this.setState({ filterDetails, activePage: pageNumber, showingFrom, showingTo });
  }
  async OnSearch()
  {
     

      let checkTokenData = localStorage.getItem("userToken");
      if(checkTokenData){
      let checkToken = JSON.parse(checkTokenData);
      
      let accessToken = checkToken.access_token;
      var params ={
          
      }
     
        //get listing 
      await axios.get(`${window.$URL}client/get-clients-accounts`,{
          params: {
            client_master_id :0,//Client accounts
          },
          headers: { "Authorization": `Bearer ${accessToken}` }
      })
        .then(response => {
            
            if (response.data.status) {

                let totalCount = response.data.data.length;
                let showingFrom = response.data.data.length > 1 ? 1 : response.data.data.length;
                let showingTo = response.data.data.length > 10 ? 10 : response.data.data.length;
                this.setState({
                    Details: response.data.data,
                    filterDetails: response.data.data.slice(0, this.state.pageLimit),
                    pageCount: Math.ceil(response.data.data.length / this.state.pageLimit),
                    totalCount,
                    showingFrom,
                    showingTo,
                    paginationCountText: 'Showing ' + showingFrom + ' to ' + showingTo + ' of ' + totalCount + ' Accounts'
                })

                if(totalCount==0){
                  toast.success("No result found", {
                      position: "bottom-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  });
                }
            }
        })
        .catch(error => {
            console.error(error.data)
        })
    //end listing

      }
  }
  async OnTransicationSearch()
  {
      
      let checkTokenData = localStorage.getItem("userToken");
      if(checkTokenData){
      let checkToken = JSON.parse(checkTokenData);
      
      let accessToken = checkToken.access_token;
      var params ={
          
      }
     
        //get listing 
      //   await axios.get(`${window.$URL}transaction/histrory`,{
      //     params: {
      //         transaction_type :  true,
      //         account:this.state.account,
      //         //form_date:moment().subtract(1, 'weeks').startOf('isoWeek').format('Y-MM-DD'),
      //        // to_date:moment().subtract(1, 'weeks').endOf('isoWeek').format('Y-MM-DD'),
      //        form_date:moment().startOf('week').format('Y-MM-DD'),
      //        to_date:moment().endOf('week').format('Y-MM-DD'),
      //     },
      //     headers: { "Authorization": `Bearer ${accessToken}` }
      // })
        await axios.get(`${window.$URL}transaction/histrory`,{
            params: {
                //account_type:this.state.account_type,
                //account_no:this.state.account_no,
                //start_to:this.state.start_to,
                //end_to:this.state.end_to,
                cm_id:checkToken.cm_id,
                lastTransications:true,
            },
                headers: { "Authorization": `Bearer ${accessToken}` }
        })
        .then(response => {
            
            if (response.data.status) { 
              let totalCount = response.data.data.transactions.length;
                this.setState({ 
                    transactions: response.data.data.transactions 
                })

                if(totalCount==0){
                  toast.success("No result found", {
                      position: "bottom-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  });
                }
            }
        })
        .catch(error => {
            console.error(error.data)
        })
    //end listing

      }
  }
  render() {
    const {
      showSweetAlert,
      modalDialog,
      filterDetails,
      activePage, totalCount, pageLimit, paginationCountText,transactions,

  } = this.state;
    return (
      <>
        <div className="innerwrapper">
          <div className="mainheading2 text-center w100 m-0">
            Welcome {this.state.name}
          </div>
          <div className="container-fluid mainArea">
            <div className="row">
              <div className="lefeArea2 col-md-6">
                <h4 className="smallheading mt-0 mb-0">Information Note</h4>
                <div className="information graybg m-0">  
                  <p> 
                    Welcome to the new Online Banking of Evidenza24-7 Private Bank.  
                  </p>
                  <p> 
                   We hope you like the new layout and the additional features.
                  </p> 
                  <p className="m-0">Customer Support<br/>
                  Evidenza24-7 Private Bank.</p> 
                </div>
                <h4 className="smallheading mt-3 mb-0">Account Balances</h4>
                <div className="tablebox p-0 mt-0"> 
                  <table className="table table-bordered table-responsive-sm">
                    <thead>
                        <tr> 
                        <th scope="col">Account No</th>
                        <th scope="col">Description</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Balance</th>
                        <th scope="col">Interest</th>
                        <th scope="col">Blocked</th>
                        <th scope="col">Available</th> 
                        <th scope="col">Status</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {
                            filterDetails.length > 0 ?
                                filterDetails.map((data, index) => {

                                    return (
                                        <tr> 
                                            <td>{data.account_no}</td>
                                            <td>{data.description}</td>
                                            <td>{data.currency}</td>
                                            <td>{data.balance}</td>
                                            <td>{data.interest}</td>
                                            <td>{data.blocked}</td> 
                                            <td>{data.available}</td>  
                                            <td className={data.status == '1' ? "bggreen" : "bgred"}>{data.status_text}</td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={5}>No result found</td>
                                </tr>
                        }
                    </tbody>
                </table>
                  <div className="card-footer">
                      <div className="row">
                          <div className="col-md-6">
                              <div className="dataTables_info" role="status" aria-live="polite">{paginationCountText}</div>
                          </div>
                          <div className="col-md-6 pagination float-right" style={{ justifyContent: 'flex-end' }}>
                              <Pagination
                                  prevPageText='Prev'
                                  nextPageText='Next'
                                  firstPageText='First'
                                  lastPageText='Last'
                                  activePage={activePage}
                                  itemsCountPerPage={pageLimit}
                                  totalItemsCount={totalCount}
                                  pageRangeDisplayed={5}
                                  onChange={this.handlePageChange.bind(this)}
                                  itemclassName="page-item"
                                  linkclassName="page-link"
                              />
                          </div>
                      </div>
                  </div> 
                  <table className="table table-bordered table-responsive-sm d-none">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Account No/IBAN</th>
                        <th scope="col">Account Description</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Balance</th>
                        <th scope="col">Available Amount</th>
                        <th scope="col">Printed Statement</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <button>
                            <i className="fa fa-search"></i>
                          </button>
                        </th>
                        <td>000010</td>
                        <td>Current Account</td>
                        <td>EUR</td>
                        <td>880.2</td>
                        <td className="colortext">880.2</td>
                        <td className="text-center">
                          <button>
                            <i className="fa fa-send bgorg"></i>
                          </button>
                        </td>
                        <td className="bgyellow">Dormant</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <button>
                            <i className="fa fa-search"></i>
                          </button>
                        </th>
                        <td>000010</td>
                        <td>USD-TRADE</td>
                        <td>USD</td>
                        <td>30,208.43</td>
                        <td className="colortext">30,208.43</td>
                        <td className="text-center">
                          <button>
                            <i className="fa fa-send bgorg"></i>
                          </button>
                        </td>
                        <td className="bgyellow">Dormant</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <button>
                            <i className="fa fa-search"></i>
                          </button>
                        </th>
                        <td>000010</td>
                        <td>stg-TRADE</td>
                        <td>EUR</td>
                        <td>277.06</td>
                        <td className="colortext">277.06</td>
                        <td className="text-center">
                          <button>
                            <i className="fa fa-send bgorg"></i>
                          </button>
                        </td>
                        <td className="bgyellow">Dormant</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <button>
                            <i className="fa fa-search"></i>
                          </button>
                        </th>
                        <td>000010</td>
                        <td>stg-TRADE</td>
                        <td>EUR</td>
                        <td>277.06</td>
                        <td className="colortext">277.06</td>
                        <td className="text-center">
                          <button>
                            <i className="fa fa-send bgorg"></i>
                          </button>
                        </td>
                        <td className="bggreen">Active</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="tablebox d-none">
                  <h4 className="smallheading">Exchange Rates</h4>
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th scope="col">Currency</th>
                        <th scope="col">Symbol</th>
                        <th scope="col">Exchange Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>EUR - Euro</td>
                        <td>€</td>
                        <td>1.0000</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>USD - US Dollar</td>
                        <td>$</td>
                        <td>1,000</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>GBP - British Pound</td>
                        <td>£</td>
                        <td>1.1770</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>USD - US Dollar</td>
                        <td>$</td>
                        <td>1,000</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>USD - US Dollar</td>
                        <td>$</td>
                        <td>1,000</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>USD - US Dollar</td>
                        <td>$</td>
                        <td>1,000</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>USD - US Dollar</td>
                        <td>$</td>
                        <td>1,000</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>USD - US Dollar</td>
                        <td>$</td>
                        <td>1,000</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>USD - US Dollar</td>
                        <td>$</td>
                        <td>1,000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="rightArea2 mt-0 col-md-6">
                <div className="tablebox mt-0 d-none">
                  <div className="form-group row m-0">
                    <div className="col-sm-8">
                      <select
                        className="form-control w-100"
                        id="exampleFormControlSelect1"
                      >
                        <option>000010-10-00 - (EUR Current Account)</option>
                        <option>a</option>
                        <option>b</option>
                      </select>
                    </div>
                    <div className="col-sm-4">
                      <select
                        className="form-control w-100"
                        id="exampleFormControlSelect1"
                      >
                        <option>Last 7 Days</option>
                        <option>Last 10 Days</option>
                        <option>Last 15 Days</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="tablebox mt-0 d-none">
                  <h4 className="smallheading">
                    Debit / Credit Activity by Day
                  </h4>
                  <img
                    src={chart}
                    alt="chart"
                    className="img-fluid w100"
                  />
                </div>
                <h4 className="smallheading m-0">Latest 10 Transactions</h4>
                <div className="tablebox p-0 m-0"> 
                  <table className="table table-bordered table-responsive">
                    <thead>
                        <tr> 
                        <th scope="col">Transaction Id</th>
                        <th scope="col">Type</th>
                        <th scope="col">Date</th> 
                        <th scope="col">Amount</th>
                        <th scope="col">From Account</th>
                        <th scope="col">From Currency</th>
                        <th scope="col">To Account</th> 
                        <th scope="col">To Currency</th>  
                        </tr>
                    </thead> 
                    <tbody>
                        {
                            transactions.length > 0 ?
                            transactions.map((data, index) => {

                                    return (
                                        <tr> 
                                            <td>{data.transaction_no}</td>
                                            <td>{data.transaction_type}</td>
                                            <td>{data.transaction_date}</td> 
                                            <td>{data.transaction_amount}</td>
                                            <td>{data.transaction_from_account_no}</td>
                                            <td>{data.transaction_from_currency}</td>
                                            <td>{data.transaction_to_account_no}</td>
                                            <td>{data.transaction_to_currency}</td>  
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={5}>No result found</td>
                                </tr>
                        }
                    </tbody>
                </table>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
