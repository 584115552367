import firebase from 'firebase'
//import firebase from "firebase/app"
import "firebase/firestore"

if(process.env.NODE_ENV === 'production'){
const config = {
    apiKey: "AIzaSyAj2UGooaRGS-bRLhkYIVapHJ6gbl9uhDY",
    authDomain: "evidenza24-7.firebaseapp.com",
    databaseURL: "https://evidenza24-7-default-rtdb.firebaseio.com",
    projectId: "evidenza24-7",
    storageBucket: "evidenza24-7.appspot.com",
    messagingSenderId: "697781150822",
    appId: "1:697781150822:web:30035bf6fbbf6c043979a7",
    measurementId: "G-C0KCB3FN39"
  };
  firebase.initializeApp(config); 
}else{
   const config = {
    apiKey: "AIzaSyDrDhd2nfjS39RUR_1LhyAjIMhgsJdM3ng",
    authDomain: "evidenza24-7-development.firebaseapp.com",
    databaseURL: "https://evidenza24-7-development-default-rtdb.firebaseio.com",
    projectId: "evidenza24-7-development",
    storageBucket: "evidenza24-7-development.appspot.com",
    messagingSenderId: "296700134305",
    appId: "1:296700134305:web:148cf43b859cbc6fae01e0"
  }; 
  firebase.initializeApp(config); 
}
  

const db = firebase.database(); 
export default db; 