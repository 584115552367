import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-toastify/dist/ReactToastify.css';  
import RightMenu from './RightMenu';
export default class editClientsAccounts extends Component {

    constructor(props) {
        super(props); 
          this.state = {
            role:'',
            filterBy:1,
            clientAccount: {
                client_mid : this.props.location.state.clientId,
                account_id : this.props.location.state.accountId,
                account_type: '',
                currency: '',
                walve_monthly_fees: 0,
                account_name: '',
                opening_details: '',
                remarks: '',
                mandate_instructions: '',
                notification: 0,
                status:0,
                block_amount:0.00,
                expiry_date:moment().format('Y-MM-DD'),
                minExpiryDate:moment().format('Y-MM-DD'),
            },
            currencyConfig:[], 
            accountTypes:[],
            mandateInstructions:[],
            validationErrors: {
                accountTypeError            :'',
                currencyError               :'', 
                accountNameError            :'',
                openingDetailsError         :'',
                remarksError                :'',
                mandateInstructionsError    :'',
                notificationError           :'',
                blockAmountError            :'', 
                expiryDateError            :'',

            },
            balance:0.00, 
            interest:0.00,
            blocked:0.00,
            available:0.00,
            blockAmounts:[] 
        };
     
    }
    async componentDidMount() {

       
        let checkTokenData = localStorage.getItem("userToken");
        if (checkTokenData) {
            let checkToken = JSON.parse(checkTokenData);
            //
            let accessToken = checkToken.access_token;
            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token: accessToken
            });
        }
        
        if (this.props.location.state) { 
            this.getConfig();
            this.getAccountDetails(this.state.filterBy);
        }else{
            this.props.history.push(`/client`);
        }
    }
    async getAccountDetails()
    {
        if(this.props.location.state.accountId)
        {
             
          
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
                let checkToken = JSON.parse(checkTokenData);
        
                let accessToken = checkToken.access_token;
        
                //get listing 
                await axios.get(`${window.$URL}client/account-detail`,{
                    params: {
                       
                        account_id        : this.props.location.state.accountId, 
                        filterBy          : this.state.filterBy

                    },
                    headers: { "Authorization": `Bearer ${accessToken}` }
                })
                .then(response => {
                    
                    if (response.data.status) {  
                        this.setState(Object.assign(this.state.clientAccount, {
                            client_mid:response.data.data.client_mid,
                            account_type:response.data.data.account_type,
                            walve_monthly_fees:response.data.data.walve_monthly_fees,
                            currency:response.data.data.currency,
                            account_name:response.data.data.account_name,
                            opening_details:response.data.data.opening_details,
                            remarks:response.data.data.remarks,
                            mandate_instructions:response.data.data.mandate_instructions,
                            notification:response.data.data.notification,
                            status:response.data.data.status,
                            }));

                        this.setState({
                            balance:response.data.data.balance,
                            interest:response.data.data.interest,
                            blocked:response.data.data.blocked,
                            available:response.data.data.available, 
                            blockAmounts:response.data.data.blockAmounts
                        });
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
                 //end listing 
            }

        }
    }
    async getConfig()
    {   
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
                let checkToken = JSON.parse(checkTokenData);
        
                let accessToken = checkToken.access_token;
        
                //get listing
                await axios.get(`${window.$URL}getConfigs?account_types=true&currency=true&mandate_instructions=true`,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
                .then(response => {
                    
                    if (response.data.status) { 
                        if(response.data.data.account_types){
                            this.setState({accountTypes:response.data.data.account_types}); 
                        }
                        if(response.data.data.currency){
                            this.setState({currencyConfig:response.data.data.currency}); 
                        }
                        if(response.data.data.mandate_instructions){
                            this.setState({mandateInstructions:response.data.data.mandate_instructions}); 
                        } 
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
                 //end listing

            } 
    }
    onChange = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target; 
         const { clientAccount } = this.state;
         this.setState({
            clientAccount: {
                 ...clientAccount,
                 [name]: value
             }
         },this.validate);
         
    }
    onBlockChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const { clientAccount } = this.state;
         this.setState({
            clientAccount: {
                 ...clientAccount,
                 [name]: value
             }
         },this.validate); 
    }
    onChange2 = (e) => {
        e.preventDefault(); 
          this.setState({ notification: e.target.value })
     } 
    validate = () => {
       const{ clientAccount } = this.state; 
        const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
        let formvalidateStatus = true;

        if (clientAccount.account_type.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{accountTypeError:'Please Select A Account'}));
            formvalidateStatus = false;
        }else{
             this.setState(Object.assign(this.state.validationErrors,{accountTypeError:''}));
        }

        if (clientAccount.currency.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{currencyError:'Please Select A Currency'}));
            formvalidateStatus = false;
        }else{
             this.setState(Object.assign(this.state.validationErrors,{currencyError:''}));
        }

        /*if (clientAccount.waive_fee.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{waveMonthlyFeesError:'select an option'}));
            formvalidateStatus = false;
        }else{
             this.setState(Object.assign(this.state.validationErrors,{waveMonthlyFeesError:''}));
        }*/

        if (clientAccount.account_name.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{accountNameError:'Please Enter Account Name'}));
            formvalidateStatus = false;
        }else{
             this.setState(Object.assign(this.state.validationErrors,{accountNameError:''}));
        }
        /*
        if (clientAccount.opening_details.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{openingDetailsError:'Enter Opening Details'}));
            formvalidateStatus = false;
        }else{
             this.setState(Object.assign(this.state.validationErrors,{openingDetailsError:''}));
        } */  

            
        if (clientAccount.mandate_instructions.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{mandateInstructionsError:'Select Mandate Instruction'}));
            formvalidateStatus = false;
        }else{
             this.setState(Object.assign(this.state.validationErrors,{mandateInstructionsError:''}));
        }

        /*if (clientAccount.notification.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{notificationError:'Select Notification'}));
            formvalidateStatus = false;
        }else{
             this.setState(Object.assign(this.state.validationErrors,{notificationError:''}));
        }*/ 
        let amount =parseFloat(this.state.clientAccount.block_amount);
        if (clientAccount.block_amount.length){
            if (clientAccount.block_amount.length < 1) { 
                this.setState(Object.assign(this.state.validationErrors,{blockAmountError:'Please Enter Debit Amout'}));
                formvalidateStatus = false;
            }else if (!floatRegExp.test(clientAccount.block_amount)){
                this.setState(Object.assign(this.state.validationErrors,{blockAmountError:'Please Enter Valid Amount'}));
                formvalidateStatus = false;
            }else if (clientAccount.block_amount < 0){
                this.setState(Object.assign(this.state.validationErrors,{blockAmountError:'Amount Must be Greater Than 0'}));
                formvalidateStatus = false;
            }else if ( amount > parseFloat(this.state.available).toFixed(2)){
                this.setState(Object.assign(this.state.validationErrors,{blockAmountError:'Account Available Balance Is '+this.state.available}));
                formvalidateStatus = false;
            }else{
                this.setState(Object.assign(this.state.validationErrors,{blockAmountError:''}));
            }

            if (clientAccount.expiry_date.length === 0){
                this.setState(Object.assign(this.state.validationErrors,{expiryDateError:'Please Select Expiry Date'}));
                formvalidateStatus = false;
            }else{
                this.setState(Object.assign(this.state.validationErrors,{expiryDateError:''}));
            }
        }
        return formvalidateStatus
    }
    onSubmit = (e) => {
        e.preventDefault();
        // alert('gii');return false;
        const {token } = this.state;
        const isValid = this.validate();
        //
        if (isValid) {
            //alert('pk');
            let accountData =  this.state.clientAccount;
            accountData.expiry_date = moment(moment(accountData.expiry_date)).format('Y-MM-DD');
                axios.post(`${window.$URL}client/update-client-accounts`, accountData,{ headers: {"Authorization" : `Bearer ${token}`}})
                .then(response => {
                    
                    if (response.data.status) {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            successMessage: response.data.message,
                            success: true,
                            pageName: "",
                            buttonData: false,
                            UpdateData: false
                        })

                        this.componentDidMount();
                        this.props.history.push(`/client/client-accounts/`+this.props.location.state.clientId);
                    } else {
                        toast.error('Something Went Wrong', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            error: true,
                            errormessage: 'Something Went Wrong'
                        })
                    }
                })
                .catch(error => {
                     
                })
        }
    }
    relaceBlockAmount = (e) => {
        e.preventDefault(); 
        let accountData =  {
            block_id:e.target.value
        };
        axios.post(`${window.$URL}client/block/amount/relese`, accountData,{ headers: {"Authorization" : `Bearer ${this.state.token}`}})
        .then(response => {
            
            if (response.data.status) {
                toast.success(response.data.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    successMessage: response.data.message,
                    success: true,
                    pageName: "",
                    buttonData: false,
                    UpdateData: false
                })

                this.getAccountDetails(this.state.filterBy);
             
            } else {
                toast.error('Something Went Wrong', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    error: true,
                    errormessage: 'Something Went Wrong'
                })
            }
        })
        .catch(error => {
            console.error(error.data)
        })
    }
    handleDateChange = (name,date) => {  
        const { clientAccount } = this.state;
        
        if(date){ 
          this.setState({
            clientAccount: {
                 ...clientAccount,
                 [name]: moment(moment(date)).format('Y-MM-DD')
             }
         });
        }else{
            this.setState({
                clientAccount: {
                     ...clientAccount,
                     [name]: ""
                 }
             });
        }
      }
     blockFilter = (e) => {
        e.preventDefault(); 
          this.setState({ filterBy: e.target.value }); 
         setTimeout(
            () =>  this.getAccountDetails(), 
            20
          );
     }
    
    render() {
        const {            
            currencyConfig,mandateInstructions,accountTypes,blockAmounts 
        } = this.state;
        let expiryNewdate = new Date();
        let currencyOption = currencyConfig.map((item, index) =>
                <option value={item.value}>{item.name}</option>
            );
        let mandateOptions = mandateInstructions.map((item, index) =>
            <option value={item.value}>{item.name}</option>
        );
        let accountTypeOptions = accountTypes.map((item, index) =>
            <option value={item.value}>{item.name}</option>
        );
        let blockedAmountList = blockAmounts.map((item, index) =>
            <tr>
                <td>{item.index}</td>
                <td>{item.amount}</td>
                <td>{item.block_date}</td>
                <td>{item.expire_date}</td>
                <td>{item.user}</td>
                { this.state.filterBy == 1 ?
                    <td><button type="button" className="btn btn-success" value={item.block_id} onClick={this.relaceBlockAmount}>Release</button></td>
                :
                    <td></td>
                }
            </tr>
        );
               
        return ( 
            <div className="wrapper">
                  <ToastContainer/>
                {/* <div className="menubox">
                    <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                        <span className="hamb-top" />
                        <span className="hamb-middle" />
                        <span className="hamb-bottom" />
                    </button>
                </div> */}
                <div className="container newContainer mainArea pt-0" id="formpage">
                    <div className="row justify-content-center">
                        {/* lefeArea start */}
                            <div className="lefeArea col-md-9 ">
                                <h3 className="heading">Edit Account</h3>  
                                    <form onSubmit={this.onSubmit}>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                            <h4 className="smallheading mt-2 mb-0">General Configuration</h4>
                                            <div className="tablebox"> 
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Account Type <sup>*</sup></label>
                                                    <div className="col-sm-8">
                                                        <select name="account_type" className="form-control" id="exampleFormControlSelect1" onChange={this.onChange}  value={this.state.clientAccount.account_type} disabled>
                                                            <option value="">Select account type</option>
                                                            {accountTypeOptions}
                                                        </select>
                                                        <p className="error">{this.state.validationErrors.accountTypeError}</p>
                                                    </div>
                                                </div>  
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Currency <sup>*</sup></label>
                                                    <div className="col-sm-8">
                                                        <select name="currency" className="form-control" id="exampleFormControlSelect1" onChange={this.onChange}  value={this.state.clientAccount.currency}>
                                                            <option value="">Select Currency</option>
                                                            {currencyOption}
                                                        </select>
                                                        <p className="error">{this.state.validationErrors.currencyError}</p>
                                                    </div>
                                                </div>  
                                            </div> 
                                            {this.state.role != "Client" &&
                                                <>
                                                <h4 className="smallheading mb-0">Charges and Fees</h4>
                                                <div className="tablebox">   
                                                    <div className="from-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Walve Monthly Fees</label>
                                                        <div className="col-sm-8"> 
                                                            <select name="walve_monthly_fees" className="form-control" onChange={this.onChange} value={this.state.clientAccount.walve_monthly_fees} id="exampleFormControlSelect1" >
                                                                    <option value="0">No</option>
                                                                    <option value="1">Yes</option>
                                                                </select>
                                                            <p className="error">{this.state.validationErrors.waveMonthlyFeesError}</p>
                                                        </div>
                                                    </div>  
                                                </div>
                                                </>
                                            }
                                            <h4 className="smallheading mb-0">General Information</h4> 
                                            <div className="tablebox"> 
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Account Name <sup>*</sup></label>
                                                    <div className="col-sm-8">
                                                        <input name="account_name" type="text" className="form-control" id="inputEmail3" placeholder="Current Account" onChange={this.onChange} value={this.state.clientAccount.account_name}/>
                                                        <p className="error">{this.state.validationErrors.accountNameError}</p>
                                                    </div>
                                                </div> 
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Mandate Instructions  <sup>*</sup></label>
                                                    <div className="col-sm-8">
                                                        <select name="mandate_instructions" className="form-control" onChange={this.onChange} id="exampleFormControlSelect1" onChange={this.onChange} value={this.state.clientAccount.mandate_instructions}>
                                                            <option>Please select</option>
                                                            {mandateOptions}
                                                        </select>
                                                        <p className="error">{this.state.validationErrors.mandateInstructionsError}</p>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Opening Details</label>
                                                    <div className="col-sm-8">
                                                        <input type="text" name="opening_details" className="form-control" id="inputEmail3" placeholder="Opening Details" onChange={this.onChange} onChange={this.onChange} value={this.state.clientAccount.opening_details}/>
                                                    </div>
                                                </div> 
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Remarks  </label>
                                                    <div className="col-sm-8">
                                                        <textarea name="remarks" className="form-control w-100" id="exampleFormControlTextarea1" onChange={this.onChange} onChange={this.onChange} value={this.state.clientAccount.remarks}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.role != "Client" &&
                                                <>
                                                    <h4 className="smallheading mb-0">Notifications</h4>
                                                    <div className="tablebox"> 
                                                        <div className="row form-group">
                                                            <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Send Notification On opening the account</label>
                                                            <div className="col-sm-8"> 
                                                                <select name="notification" className="form-control" onChange={this.onChange} value={this.state.clientAccount.notification} id="exampleFormControlSelect1" disabled>
                                                                    <option value="">Please select</option>
                                                                    <option value="1">Yes</option>
                                                                    <option value="0">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </>
                                            }  
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                {this.state.role != "Client" &&
                                                    <>
                                                        <h4 className="smallheading mb-0">Account Status</h4>
                                                        <div className="tablebox"> 
                                                            <div className="row form-group">
                                                                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Status</label>
                                                                <div className="col-sm-8"> 
                                                                    <select name="status" className="form-control" onChange={this.onChange} value={this.state.clientAccount.status} id="exampleFormControlSelect1" >
                                                                        <option>Please select</option>
                                                                        <option value="1">Active</option>
                                                                        <option value="0">Pending</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h4 className="smallheading mt-2 mb-0">Balance</h4>
                                                        <div className="tablebox"> 
                                                            <div className="row form-group"> 
                                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Balance</label>
                                                                    <div className="col-sm-8">
                                                                        <input type="text" readOnly value={this.state.balance} className="form-control" readOnly /> 
                                                                    </div>
                                                            </div> 
                                                            <div className="row form-group"> 
                                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Available</label>
                                                                    <div className="col-sm-8">
                                                                        <input type="text" readOnly value={this.state.available} className="form-control" readOnly /> 
                                                                    </div>
                                                            </div>
                                                            <div className="row form-group"> 
                                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Interest</label>
                                                                    <div className="col-sm-8">
                                                                        <input type="text" readOnly value={this.state.interest} className="form-control" readOnly /> 
                                                                    </div>
                                                            </div>
                                                            <div className="row form-group"> 
                                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Blocked</label>
                                                                    <div className="col-sm-8">
                                                                        <input type="text" readOnly value={this.state.blocked} className="form-control" readOnly /> 
                                                                    </div>
                                                            </div>
                                                            <div className="row form-group d-none"> 
                                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Available</label>
                                                                    <div className="col-sm-8">
                                                                        <input type="text" readOnly value={this.state.available} className="form-control" readOnly /> 
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <h4 className="smallheading mt-2 mb-0">Managed Block Amount</h4>
                                                            <div className="tablebox"> 
                                                                <div className="row form-group"> 
                                                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Enter Block Amount</label>
                                                                        <div className="col-sm-8">
                                                                            <input type="number" name="block_amount" value={this.state.clientAccount.block_amount}  onChange={this.onBlockChange}  max={this.state.available} step="2" className="form-control" /> 
                                                                            <p className="error">{this.state.validationErrors.blockAmountError}</p>
                                                                        </div>
                                                                </div> 
                                                                <div className="row form-group"> 
                                                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Expiry Date</label>
                                                                    
                                                                        <div className="col-sm-8 customdatesm">
                                                                            <DatePicker placeholderText="yyyy-mm-dd" dateFormat="yyyy-mm-dd" className="form-control " value={ this.state.clientAccount.expiry_date } selected={this.state.clientAccount.expiry_date && new Date(this.state.clientAccount.expiry_date)}   minDate={new Date(moment(this.state.minExpiryDate))} name="expiry_date"   onChange={(date) => {
                                                                                this.handleDateChange('expiry_date',date);}}  
                                                                            showYearDropdown
                                                                            scrollableMonthYearDropdown  /> 
                                                                            <p className="error">{this.state.validationErrors.expiryDateError}</p>
                                                                        </div>
                                                                </div>
                                                            </div> 
                                                        </div> 
                                                    </>
                                                }
                                                 <div className="">
                                                    <div className="panel-group" id="accordionGroupClosed" role="tablist" aria-multiselectable="true">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading" role="tab" id="headingOne">
                                                                <h4 className="panel-title">
                                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupClosed" href="#collapseCloseOne" aria-expanded="true" aria-controls="collapseCloseOne">
                                                                    Blocked Amount
                                                                    </a>
                                                                </h4> 
                                                            </div>
                                                            <div id="collapseCloseOne" className="panel-collapse collapse " role="tabpanel" aria-labelledby="headingOne">
                                                                
                                                                <div className="mb-5">  
                                                                            <label htmlFor="inputEmail3" className="col-form-label">Filter</label>
                                                                        
                                                                            <select className="form-control" onChange={this.blockFilter}>
                                                                                <option value="1">Not Release</option>
                                                                                <option value="2">Released</option>
                                                                            </select> 
                                                                </div>
                                                                 
                                                                <div className="tablebox p-0 w-100">
                                                                    <table className="table table-bordered table-responsive-sm">
                                                                        <thead>
                                                                            <tr>
                                                                            <th scope="col">Id</th>
                                                                            <th scope="col">Amount</th>
                                                                            <th scope="col">Execution Date</th>
                                                                            <th scope="col">Expiry Date</th>
                                                                            <th scope="col">User</th> 
                                                                            <th scope="col">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {blockedAmountList}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                    <div className="form-group row text-center mt-2 ml-auto">
                                                        <div className="col-sm-12">
                                                            <div className="btnwrp">
                                                                    <Link
                                                                        className="btn btn-primary bluebg"
                                                                        to={{
                                                                        pathname: `/client/client-accounts/${this.props.location.state.clientId}`, 
                                                                        clientId:this.props.clientId,
                                                                        state: {
                                                                            clientId: this.props.clientId  
                                                                        }
                                                                        }}
                                                                    >
                                                                    Back</Link>
                                                                <button type="submit" className="btn btn-primary bluebg">Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>  
                                    </form>  
                            </div> 
                        {/* lefeArea end */} 
                        <RightMenu clientId={ this.props.location.state.clientId} activeMenu="client_accounts"/>  
                    </div>
                </div>
            </div> 
        );
    }
}
