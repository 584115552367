import React, { Component } from 'react'; 
import { Link } from 'react-router-dom';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
export default class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name : '',
            role : '',
            pageName: window.location.pathname.split("/"),
            showSidebar:'',
            hamburger:'is-closed',
            sidebaroverlay:'none'
        }
        this.sideBar = this.sideBar.bind(this);
    }
    async componentDidMount(){
    let checkTokenData = localStorage.getItem("userToken");
    let checkToken = JSON.parse(checkTokenData);
    //
    this.setState({
      name:checkToken.name,
      role:checkToken.role
    })
  }
  
    sideBar() {  
        if(this.state.hamburger == 'is-closed'){
            this.setState({
                showSidebar: 'toggled',
                hamburger: 'is-open',
                sidebaroverlay:'block'
            });
        }else{
            this.setState({
                showSidebar: '',
                hamburger: 'is-closed',
                sidebaroverlay:'none'
            });
        }  
    }
    render() {
        const {pageName} = this.state;
        return (
            <div className="left-sidebar">
                <div className="menubox">
                  <button type="button" className={'hamburger animated fadeInLeft ' + this.state.hamburger} data-toggle="offcanvas" onClick={this.sideBar}>
                    <span className="hamb-top"></span>
                    <span className="hamb-middle"></span>
                    <span className="hamb-bottom"></span>
                  </button>
                </div>
                  <div id="wrapper" className={this.state.showSidebar}>
                    <div className="overlay"  style={{display : this.state.sidebaroverlay}}></div>  
                        <nav className="navbar navbar-inverse fixed-top" id="sidebar-wrapper" role="navigation">
                          <ul className="nav sidebar-nav">
                            <div className="sidebar-header">
                              <div className="sidebar-brand">
                              <img src={require("../../../assets/images/frux-logo.png")} alt="logo" className="img-fluid" />
                              </div>
                            </div> 
                            { this.state.role === 'Super_Admin' &&
                              <li> 
                                  <Link to="/team" onClick={this.sideBar}><i className='fa fa-users' aria-hidden="true"></i> Team</Link> 
                              </li>
                            }
                            <li>
                              <Link to="/client" onClick={this.sideBar}> <i className='fa fa-users' aria-hidden="true"></i> Clients</Link>
                            </li>
                            <li className="">
                                <Link to="/transactions" onClick={this.sideBar}> <i className='fa fa-exchange' aria-hidden="true"></i> Transactions</Link>
                             </li>
                            <li>
                                <Link to="/product" onClick={this.sideBar}><i className='fa fa-podcast' aria-hidden="true"></i> Product</Link>
                             </li>
                            <li> 
                                <Link to="/currency-wallet" onClick={this.sideBar}><i class='fa fa-usd' aria-hidden="true"></i> Currency Wallet</Link> 
                            </li> 
                            <li>
                                <Link to="/reports" onClick={this.sideBar}><i class='fa fa-list-alt' aria-hidden="true"></i> Reports</Link> 
 
                            </li>
                          </ul>
                        </nav> 
                  </div> 
            </div>
        )
    }
}

