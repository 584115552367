import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

const initialState = {
  email: "",
  password: "",
  loading: false, 
  // emailError: "",
  // passwordError: "",
  validationErrors :{ 
    emailError: '',
   // createdAtError: '',
    passwordError: '',
},
  Isloggedin: false,
  success: false,
  successMessage: "",
  errror: false,
  errorMessage: "",
  loginBtnLoader:false
};

export default class Login extends Component {
  state = initialState;

  onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value })
  };

  validate = () => {
    const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        let emailError = '';
        let passwordError = '';

        let formvalidateStatus = true;  

        if (this.state.email.length < 1) {
            emailError = "Please enter Email";
        } else if (!regex.test(this.state.email.trim())) {
            emailError = "Please enter a valid Email"
        }      
        if (emailError) { 
            this.setState(Object.assign(this.state.validationErrors,{emailError:emailError}));
            formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{emailError:''}));
        }
          
        if (this.state.password.length < 1) {
            passwordError = "Please enter Password";
        }
        if (passwordError) {
            this.setState(Object.assign(this.state.validationErrors,{passwordError:passwordError}));
            formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{passwordError:''}));
        } 
        return formvalidateStatus
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({loginBtnLoader:true});
    const { email, password } = this.state;
    const isValid = this.validate(); 
    if (isValid) { 
      axios
        .post(`${window.$URL}login`, { email, password })
        .then((response) => { 
          if (response.data.status === true) {
             
            var userTokenData ={
              name:response.data.data.name,
              role:response.data.data.role,
              access_token:response.data.data.access_token,
              cm_id :response.data.data.cm_id 
              };
            localStorage.setItem("userToken",JSON.stringify(userTokenData));
            if(response.data.data.role === 'Client'){ 
              this.props.history.push(`/client-portla/dashboard`);
            }else{
              this.props.history.push(`/dashboard`);
            }
         
            ///console.log(response.data.status);
          } else {
            this.setState({
              Isloggedin: false,
              errror: true,
              errorMessage: response.data.error,
              loginBtnLoader:false
            });
          }
          
        })
        .catch((error) => {
          this.setState({
            errror: true,
            errorMessage: error.data,
            loginBtnLoader:false
          });
        });
    }else{
      this.setState({ 
        loginBtnLoader:false
      });
    }
  };

  render() {
    return (
      <div className="main-container">
        <div className="loginarea">
          <div className="logo">
            <img
              src={require("../../assets/images/frux-logo.png")}
              alt="logo"
              className="img-fluid"
            />
          </div>
          <h4>Internet Banking</h4>
          {
                                 this.state.errror
                                   ?
                                   <>
                                         <p style={{ color: "red" }}>{this.state.errorMessage}.</p>
                                   </>
                                   :
                                 ""
                         }
                            {
                                this.state.success
                                     ?
                                     <>
                                         <p style={{ color: "green" }}>{this.state.successMessage}.</p>
                                </>
                                :
                             ""
             }
          <form onSubmit={this.onSubmit} autocomplete="on">
            <div className="logininner d-flex justify-content-center row">
              <div className="form-group input-group">
                <label className="has-float-label m-0" for="lgnForm_UserName">
                  <i className="fa fa-user input-group-addon"></i>
                </label>
                <input
                  className="form-control"
                  id="lgnForm_UserName"
                  type="email"
                  name="email"
                  placeholder="Enter Your User Id"
                  onChange={this.onChange}
                  autocomplete="on"
                />
               <p className="error-login">{this.state.validationErrors.emailError}</p>
              </div>
              <div className="form-group input-group">
                <label className="has-float-label m-0" for="lgnForm_pass">
                  <i className="fa fa-lock input-group-addon"></i>
                </label>
                <input
                  className="form-control"
                  id="lgnForm_pass"
                  type="password"
                  name="password"
                  placeholder="Enter Your Password"
                  onChange={this.onChange}
                />
                <p className="error-login">{this.state.validationErrors.passwordError}</p>
              </div>
              <button
                type="submit"
                name="lgnForm:LoginButton" 
                id="lgnForm_LoginButton"
              >
              <div className="buttonloader"><div className={`loader_wrap ${ this.state.loginBtnLoader ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>
              Log In
              </button>
            </div>
          </form>

          <div className="d-flex justify-content-between maxtt">
              <Link to="/forgot-password">Forgot your Password?</Link>  
          </div>
        </div>
      </div>
    );
  }
}
